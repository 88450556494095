import Dropzone from 'dropzone';
import axios, { AxiosRequestConfig } from 'axios';
import md5 from 'md5';
import { Component } from '@/shared/component';
import { useApplicationStore } from '@/stores'
import { Pinia } from 'pinia';


export class ContactUs extends Component {
    dropzone = null;
    contactUsElement: HTMLElement = null;
    formElement: HTMLFormElement = null;
    contactUsCaptchaInputElement: HTMLFormElement = null;
    hcaptchaWidgetID: string = null;


    constructor(store: Pinia) {
        super(store);
    }

    async init() {     
        await super.init();
        
        this.contactUsElement = document.querySelector('.contact-us');

        this.formElement = document.querySelector('.contact-us__form');
        this.formElement.addEventListener('submit', (event) => this._onSubmit(event));
        
        const newRequestButton = document.querySelector('.contact-us__button--send-another-form');
        newRequestButton.addEventListener('click', (event) => this._newRequest());

        this.contactUsCaptchaInputElement = document.querySelector('.contact-us__h-captcha-input');

        this._initDropZone();   
        this._initCaptcha();
    }

    async _initCaptcha(){
        const store = useApplicationStore();
        const unsubscribe = store.$subscribe((mutation, state) => {
            if (state.captchaOnloaded === true){
                const hcaptchaConfig: ConfigRender = {
                    sitekey: '052a7033-a2ec-446b-884b-3a7fa01e2ba0',                  
                    callback: (response) => {                        
                        this.contactUsCaptchaInputElement.value = response;
                    },
                    "expired-callback": () => {
                        this.contactUsCaptchaInputElement.value = '';
                    }

                };

                this.hcaptchaWidgetID = window['hcaptcha'].render('hcaptchaContainer', hcaptchaConfig);
        

                unsubscribe();
            }
        });
    }
    _resetCaptcha() {
        hcaptcha.reset(this.hcaptchaWidgetID);
        this.contactUsCaptchaInputElement.value = '';
    }
    
    _newRequest() {
        this.contactUsElement.classList.remove('contact-us--request-completed')
    }

    _initDropZone() {
        Dropzone.autoDiscover = false;
        const previewTemplate = `
            <li class="contact-us__file-item" class="files">
                <div class="contact-us__file-preview">
                    <span class="preview"><img data-dz-thumbnail class="contact-us__file-preview-image" /></span>
                </div>
                <div class="contact-us__file-name-container">
                    <p class="contact-us__file-name name" data-dz-name></p>
                    <strong class="error text-danger" data-dz-errormessage></strong>

                </div>
                <div class="contact-us__file-status">
                    <div class="contact-us__file-status-icon-container">
                        <svg class="contact-us__file-status-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 6L9 17L4 12" stroke="#00A71A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <span class="contact-us__file-status-title contact-us__file-status-title--uploading">Waiting</span>
                    <div class="contact-us__progressbar" role="progressbar" data-dz-uploadprogress>
                        <span class="contact-us__progressbar-value"></span>
                    </div>
                </div>
                <div class="contact-us__file-delete">
                  <button data-dz-remove class="contact-us__button contact-us__button--delete">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M18 2L2 18" stroke="#82979B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 2L18 18" stroke="#82979B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
            </li>
            `;

        this.dropzone = new Dropzone('.contact-us__upload-container', {
            url: 'https://api.forms.snappii.com/v0/upload',//https://snappii.com/handlers/UploadHandler.ashx?action=uploadAppRequestFile',
            previewsContainer: '.contact-us__upload-file-list',
            acceptedFiles: 'image/*, application/pdf, .xls, .xlsx',
            previewTemplate,
            hiddenInputContainer: '.contact-us',
            method: 'post'
        });

        this.dropzone.on('sending', (file, xhr, formData) => {
            let date = new Date();
            let datestring = date.getTime();
            let ext = file.name.split('.').pop();
            let currentUploadFilename = md5(datestring + file.name) + '.' + ext;

            formData.append('timehash', currentUploadFilename);
            file.previewElement.classList.add('contact-us__file-item--uploading');
            file.previewElement.querySelector('.contact-us__file-status-title').textContent = `Uploading`;
        });

        this.dropzone.on('uploadprogress', (progress, progressPercentage) => {
            const progressRoundPercentage = Math.round(progressPercentage);
            progress.previewElement.querySelector('.contact-us__progressbar-value').textContent = `${progressRoundPercentage}%...`;
        });


        this.dropzone.on('success', file => {
            try {
                let result = JSON.parse(file.xhr.response)
                const inputElement = document.createElement('input');
                inputElement.setAttribute('type', 'hidden');
                inputElement.setAttribute('name', 'file');           
                inputElement.value = result.url;

                file.previewElement.classList.remove('contact-us__file-item--uploading');
                file.previewElement.classList.add('contact-us__file-item--uploaded');

                file.previewElement.querySelector('.contact-us__file-status-title').textContent = `Uploaded`;
                file.previewElement.querySelector('.contact-us__progressbar-value').textContent = ``;
                file.previewElement.append(inputElement);
            } catch (e) {
                //
            }

        });
    }
    async _onSubmit(event) {
        event.preventDefault();
        let fieldsValid: boolean = this.formElement.checkValidity();

        if (fieldsValid) {
            const formData = new FormData(this.formElement);

            const contactUsAxiosConfig: AxiosRequestConfig = {
                method: 'post',
                url: 'https://api.forms.snappii.com/v0/form',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            };

            try {
                this.contactUsElement.classList.add('contact-us--loading');
                let response = await axios(contactUsAxiosConfig);

                if (response.data === 'Ok') {
                    this.formElement.reset();
                    this._resetCaptcha();
                    this.dropzone.files.forEach((file) => this.dropzone.removeFile(file));
                }

                this.contactUsElement.classList.remove('contact-us--loading');
                this.contactUsElement.classList.add('contact-us--request-completed');
            } catch (e) {

            }              
        } 
    }
} 