import { Navigation } from '@/components/navigation';
import { useApplicationStore } from '@/stores';
import { Pinia, createPinia, setActivePinia } from 'pinia';

export class Page {
    navigation = null;
    store: Pinia = null;

    constructor(){
        this.store = createPinia();
        setActivePinia(this.store);

        window['captchaOnloaded'] = (event) => {
            if (window['hcaptcha'] !== undefined){
                const { updateCaptchaOnloaded } = useApplicationStore();
                updateCaptchaOnloaded(true);
            }
          }
    }

    async init(){
        this.navigation = new Navigation(this.store);
        this.navigation.init();
    }
}