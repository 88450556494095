import { Component } from '@/shared/component';
import createFocusTrap from 'focus-trap';
import { Pinia } from 'pinia';

export class Navigation extends Component {
  navigationElement: HTMLElement = null;
  toggleBtn: HTMLElement = null;
  isOpen: boolean = false;

  constructor(store: Pinia) {
    super(store);
  }

  async init(){
    await super.init();

    this.navigationElement = document.querySelector('.main-nav');
    this.toggleBtn = this.navigationElement.querySelector('.main-nav__hamburger-button');
    this.toggleBtn.addEventListener('click', () => this.toggleMenu(false));

    // this.isOpen = false;
    // this.activeSubmenuContainer = null;
    // this.selectedMenuItems = [];
    
    // this.submenuHeader = document.querySelector('.main-nav__submenu-header');
    
    // this.focusTrap = createFocusTrap(this.nav);
    // 
    // this.initSubMenuButtons();
    // this.initCloseButton();
  }

  toggleMenu(force: Boolean) {
    this.isOpen = typeof force === 'boolean' ? force : !this.isOpen;
    document.body.classList.toggle(
      'page-body--modal-is-showed', 
      this.isOpen
    );

    
    this.navigationElement.classList.toggle(
      'main-nav--is-open', 
      this.isOpen
    );

    this.toggleBtn.classList.toggle(
      'main-nav__hamburger-button--active',
      this.isOpen
    );

    // this.submenuHeader.classList.toggle(
    //   'main-nav__submenu-header--active',
    //   this.isOpen
    // );


  //   this.toggleBtn.setAttribute('aria-expanded', String(this.isOpen));

  //   if (this.isOpen) {
  //     this.focusTrap.activate();
  //   } else {
  //     this.focusTrap.deactivate();
  //   }

    }
}
