import { Page } from '@/shared/page';
import { ContactUs } from '@/components/contact-us';

class HomePage extends Page {
  contactUs = null;

  async init() {
    await super.init();

    this.contactUs = new ContactUs(this.store);
    await this.contactUs.init();
  }
}

const application = new HomePage();
window['application'] = application;
application.init();
