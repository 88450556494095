import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApplicationStore = defineStore('application', () => {
  const captchaOnloaded = ref<Boolean>(false);

  function updateCaptchaOnloaded(value: Boolean) {
    captchaOnloaded.value = value;
  }

  return { captchaOnloaded, updateCaptchaOnloaded };
})