import { Pinia } from 'pinia';

export class Component {
    store: Pinia = null;

    constructor(store: Pinia){
        this.store = store;
    }

    async init(){
    }
}